import React from "react";
import HospitalDashboardLayout from "../../layout/hospital";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoToggleOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import Dpimg from "../../../images/dp.png";

const DoctorDetail = () => {
  return (
    <HospitalDashboardLayout>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-black font-[500] text-[32px] leading-[44px]">
            Doctor
          </h2>

          <div className="flex items-center mt-[2px] ">
            <MdKeyboardArrowRight className=" text-[#B0B0B0]" size={25} />
            <MdKeyboardArrowRight size={32} className="-ml-[19px] text-grey" />
          </div>
          <h3 className="text-lg font-[500]">Fola Gbade</h3>
        </div>

        <div className="flex items-center gap-3">
          <button className="w-[154px] h-[45px] bg-[#47BD681A] border-[0.7px] border-[#34C759] gap-2 flex justify-center items-center rounded-full ">
            <div className="w-[5px] h-[5px] rounded-full bg-[#34C759]"></div>
            <p className="text-[#34C759] text-sm font-[500]">Active</p>
          </button>

          <button className="w-[141px] h-[45px] bg-[#FFCC00]  gap-2 flex justify-center items-center rounded-full ">
            <IoToggleOutline />
            <p className=" text-sm font-[500]">Deactivate</p>
          </button>

          <button className="w-[154px] h-[45px] bg-white border-[0.7px] border-[#FF3B30] gap-2 flex justify-center items-center rounded-full ">
            <AiOutlineDelete className="text-[#FF3B30]" />
            <p className=" text-sm font-[500]">Delete Profile</p>
          </button>
        </div>
      </div>

      <div className="w-full h-[194px] flex flex-col justify-between items-center bg-white mt-[50px] rounded-[20px] font-poppins p-[30px]">
        <div className="w-full h-full flex justify-between   ">
          <div className="w-[370px] h-full flex items-center gap-4 ">
            <img
              src={Dpimg}
              className="w-[100px] h-[100px] rounded-full"
              alt=""
            />
            <div className="">
              <h3 className="text-[32px] font-[500]">Fola Gbade</h3>
              <p className="text-lg font-[500]">Oncology Department</p>
              <p className="text-[16px] font-[500] text-grey">
                folagbade112@mail.com
              </p>
            </div>
          </div>

          <div className="w-[245px] h-full flex flex-col justify-between items-center border-r border-[#D7D7D7] border-l">
            <p className="text-[16px] font-[500] text-grey">Patients</p>
            <p className="text-[64px] font-[500]">13</p>
          </div>

          <div className="w-[310px] h-full flex flex-col justify-between ">
            <div>
              <p className="text-[16px] font-[500] text-grey">Date Joined</p>
              <p className="text-[16px] font-[500]">
                October 18th 2024, 6:26:14 am
              </p>
            </div>
            <button className=" px-4 rounded-full bg-white  flex items-center justify-center h-[45px] border-[0.7px] border-[#D7D7D7] w-[127px]">
              <BiEditAlt className="text-grey text-[22px]" />
              <span className="ml-1 text-[12px] font-[500]">Edit Profile</span>
            </button>
          </div>
        </div>
      </div>

      <div className="mt-[50px] w-full rounded-[20px] p-[25px] bg-white">
        <div className="flex justify-between ">
          <div className="w-[45%]">
            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">First Name</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value="Harsh Tripathi"
                disabled={true}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Email Address</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value="trpathihhk2014@gmal.com"
                disabled={true}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Department</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value="Oncology"
                disabled={true}
              />
            </div>
          </div>

          <div className="w-[45%]">
            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Last Name</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value=" Tripathi"
                disabled={true}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Phone Number</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value="637522691"
                disabled={true}
              />
            </div>

            <div className="mb-5">
              <p className="text-[16px] font-[500] mb-3">Staff ID Number</p>
              <input
                type="text"
                className="text-[16px] font-[500] w-full outline-none border-[2px] border-[#EFEBEB] h-[50px] rounded-[10px] px-5"
                value="#222345"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </HospitalDashboardLayout>
  );
};

export default DoctorDetail;
