import React, { useState } from "react";
import { FiSun } from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import HomeCards from "../../../component/Pateint/HomeCards";
import { CiSearch } from "react-icons/ci";
import { HiArrowLongRight } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineTranslate } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { PiChatCenteredText } from "react-icons/pi";
import { CiMicrophoneOn } from "react-icons/ci";

import SimplifyIcon from "../../../../images/simplify.svg";
import { useNavigate } from "react-router-dom";
import AddPatientModal from "../../../component/common/modal";
import HospitalDashboardLayout from "../../../layout/hospital";
import AddDoctor from "../../../component/common/modal/AddDoctor";

const DoctorV2 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSimplify, setIsSimplify] = useState(true);
  const [showTile, setShowTile] = useState(false);
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      role: "Admin",
      status: "Active",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane@example.com",
      role: "Editor",
      status: "Pending",
    },
    {
      id: 3,
      name: "Mike Johnson",
      email: "mike@example.com",
      role: "Viewer",
      status: "Inactive",
    },
    {
      id: 4,
      name: "Emily Brown",
      email: "emily@example.com",
      role: "Admin",
      status: "Active",
    },
    {
      id: 5,
      name: "Chris Green",
      email: "chris@example.com",
      role: "Editor",
      status: "Pending",
    },
  ];

  return (
    <HospitalDashboardLayout>
      <div className="flex items-center justify-between mb-4 ">
        <div className="flex flex-row items-center">
          <h2 className="text-3xl ml-[10px] font-[500]">Doctors</h2>
          <div className="flex items-center ml-[25px] w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
            <CiSearch
              color="black"
              className="w-[24px] h-[24px]  absolute left-[10px]"
            />
            <input
              className=" w-full outline-none ml-[45px] mr-[10px] placeholder-[#7C7C7C]"
              type="text"
              placeholder="Find Doctor"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <button
            onClick={() => setShowTile(true)}
            className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
          >
            <img
              className="w-[20px] h-[20px]"
              src={require("../../../../images/grid-view.png")}
              alt=""
            />
          </button>

          <button
            onClick={() => setShowTile(false)}
            className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
          >
            <img
              className="w-[20px] h-[20px]"
              src={require("../../../../images/p-menu.png")}
              alt=""
            />
          </button>

          <button className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]">
            <img
              className="w-[18px] h-[18px]"
              src={require("../../../../images/sort.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] text-black font-[500]">Sort</span>
          </button>

          <AddDoctor />
        </div>
      </div>
      {showTile ? (
        <div className="p-4 mt-16">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {Array.from({ length: 9 }).map((_, index) => (
              <button onClick={() => navigate("1")}>
                <div
                  key={index}
                  className="p-4 bg-white border border-gray-200 rounded-[20px] shadow-sm hover:shadow-lg transition-shadow duration-300"
                >
                  <div className="flex flex-row items-center justify-between">
                    <h3 className="  text-[14px] font-[500]">Malik Marshall</h3>
                    <div className="flex flex-row items-center bg-[#47BD681A] border-[#47BD68] border-[1px] rounded-[100px] px-[15px] py-[5px] ">
                      <div className="h-[5px] w-[5px] mr-[15px] rounded-[100%] bg-[#47BD68]"></div>
                      <h3 className="text-[12px] text-[#47BD68] font-[500]">
                        Active
                      </h3>
                    </div>
                  </div>
                  <div className="w-full flex justify-start">
                    <span className="text-[12px] font-[400] text-start">
                      mmarshall@gmail.com
                    </span>
                  </div>
                  <div className="flex flex-row items-center justify-between my-[20px]">
                    <span className="text-[#7C7C7C] text-[14px] font-[400]">
                      Department
                    </span>
                    <span className="text-[#000] text-[14px] font-[400]">
                      Oncology
                    </span>
                  </div>
                  <div className="flex flex-row items-center justify-between my-[20px]">
                    <span className="text-[#7C7C7C] text-[14px] font-[400]">
                      Staff ID Number
                    </span>
                    <span className="text-[#000] text-[14px] font-[400]">
                      #002153
                    </span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto border border-gray-200 rounded-[18px] shadow-sm mt-5 mb-4">
            <table className="w-full text-left bg-white">
              <thead className="bg-white">
                <tr>
                  <th className="p-3 text-sm font-semibold text-[#000000] w-[20%]">
                    Doctor
                  </th>
                  <th className="p-3 text-sm font-semibold text-gray-700 w-[20%]">
                    Email Address
                  </th>
                  <th className="p-3 text-sm font-semibold text-gray-700 w-[20%]">
                    Department
                  </th>
                  <th className="p-3 text-sm font-semibold text-gray-700 w-[20%]">
                    Staff ID Number
                  </th>
                  <th className="p-3 text-sm font-semibold text-gray-700 w-[20%]">
                    Status
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="overflow-x-auto border border-gray-200 rounded-[18px] shadow-sm">
            <table className="w-full text-left bg-white">
              <tbody>
                {data.map((user) => (
                  <tr key={user.id} className="border-b hover:bg-gray-50">
                    <td className="p-3 text-sm text-[#000000] w-[20%]">
                      {user.name}
                    </td>

                    <td className="p-3 text-sm text-gray-600  w-[20%]">
                      {user.email}
                    </td>

                    <td className="p-3 text-sm text-gray-600  w-[20%]">
                      Oncology
                    </td>
                    <td className="p-3 text-sm text-gray-600  w-[20%]">
                      #002153
                    </td>
                    <td className="p-3 text-sm text-gray-600 w-[20%]">
                      <button className="w-[100px] px-[16px] bg-[#47BD681A] py-2 border-[.7px] border-[#47BD68] rounded-full flex items-center justify-center gap-2">
                        <div className="w-[5px] h-[5px] rounded-full bg-[#47BD68] "></div>
                        <p className="text-xs font-[500] text-[#47BD68]">
                          Active
                        </p>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </HospitalDashboardLayout>
  );
};

export default DoctorV2;
