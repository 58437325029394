import React, { useState } from "react";

const AddDoctor = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);
  return (
    <div>
      <button
        onClick={toggleModal}
        className="w-[140px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]"
      >
        <img
          className="w-[24px] h-[24px]"
          src={require("../../../../images/add-circle.png")}
          alt=""
        />
        <span className="ml-1 text-[12px] font-[500]">New Doctor</span>
      </button>

      {/* Modal */}
      {isOpen && (
        <div
          //   onClick={toggleModal}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white w-full max-w-md rounded-[20px] shadow-lg p-8">
            <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">
              New Doctor
            </h2>

            {/* Form */}
            <form className="space-y-4">
              {/* First Name */}
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter first name"
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter last name"
                />
              </div>

              {/* Patient Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Doctor Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter patient email"
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Department
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter patient email"
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Staff ID
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter patient email"
                />
              </div>

              {/* Submit Button */}
              <div className="flex flex-col justify-end space-x-4">
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-primary   rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Send Verification link
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="px-4 py-2 text-gray-600    rounded-md focus:outline-none"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddDoctor;
