import React, { useState } from "react";
import HospitalDashboardLayout from "../../../layout/hospital";
import { CiSearch } from "react-icons/ci";
import { BiEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

const HospitalDepartments = () => {
  const [isManageAccount, setisManageAccount] = useState(false);

  const rows = [
    {
      id: 1,
      departmeant: "Oncology ",
      dId: "#44162",
      accounts: 7,
      doctors: 23,
    },
    {
      id: 2,
      departmeant: "Oncology ",
      dId: "#44162",
      accounts: 7,
      doctors: 23,
    },
    {
      id: 3,
      departmeant: "Oncology ",
      dId: "#44162",
      accounts: 7,
      doctors: 23,
    },
  ];
  return (
    <HospitalDashboardLayout>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-black font-[500] text-[32px] leading-[44px]">
            Departments
          </h2>

          <div className="flex items-center my-4  w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
            <CiSearch
              color="black"
              className="w-[24px] h-[24px]  absolute left-[10px]"
            />
            <input
              className=" w-full outline-none ml-[45px] mr-[10px] text-sm placeholder-[#7C7C7C]"
              type="text"
              placeholder="Find Department"
            />
          </div>
        </div>

        <div className="flex items-center gap-4">
          {isManageAccount ? (
            <button
              className=" px-4 rounded-full bg-secondary text-white  flex items-center justify-center h-[45px]"
              onClick={() => setisManageAccount(false)}
            >
              <BiEditAlt className=" text-[22px]" />
              <span className="ml-1 text-[12px] font-[500]">
               Save Changes
              </span>
            </button>
          ) : (
            <button
              className=" px-4 rounded-full bg-white  flex items-center justify-center h-[45px]"
              onClick={() => setisManageAccount(true)}
            >
              <BiEditAlt className="text-grey text-[22px]" />
              <span className="ml-1 text-[12px] font-[500]">
                Manage Assigned Account
              </span>
            </button>
          )}

          <button className="w-[171px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]">
            <img
              className="w-[24px] h-[24px]"
              src={require("../../../../images/add-circle.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] font-[500]">New Department</span>
          </button>
        </div>
      </div>

      <div className="w-full mb-4 h-[53px] rounded-[20px] px-5 py-4 bg-white flex items-center text-[16px] font-[500]">
        <div className="w-[34%]">
          <p className="  text-[#000] text-start">Department</p>
        </div>

        <div className="w-[24%] ">
          <p className="  text-[#747474] ">Department ID</p>
        </div>

        <div className="w-[24%]  ">
          <p className="  text-[#747474]">Assigned Accounts</p>
        </div>

        <div className="w-[18%] flex justify-end ">
          <p className="  text-[#747474]">Active Doctors</p>
        </div>
      </div>

      <div className="w-full bg-white  rounded-[20px] font-poppins">
        {rows.map((row, index) => (
          <div
            key={row.id}
            className={`p-4  w-full text-sm font-[500] flex items-center ${
              index !== rows.length - 1 ? "border-b border-[#D7D7D7]" : ""
            }`}
          >
            <Link to={`/v2/hospital/department/${row?.departmeant}`} className="w-[34%]">{row?.departmeant}</Link>
            <p className="w-[24%] flex justify-center mr-[80px]">{row?.dId}</p>

            {isManageAccount ? (
              <div className="w-[24%] flex items-center gap-3 ml-[65px]">
                <div className="w-[30px] h-[30px] rounded-lg bg-primary flex justify-center items-center text-white text-[18px]">
                  +
                </div>
                <p>{row?.accounts}</p>
                <div className="w-[30px] h-[30px] rounded-lg bg-primary flex justify-center items-center text-white text-lg">
                  -
                </div>
              </div>
            ) : (
              <p className="w-[24%] flex justify-center mr-[80px]">
                {row?.accounts}
              </p>
            )}

            <p className="w-[18%] flex justify-end mr-[55px]">{row?.doctors}</p>
          </div>
        ))}
      </div>
    </HospitalDashboardLayout>
  );
};

export default HospitalDepartments;
